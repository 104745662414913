.home_container {
  display: flex;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  margin-top: $navHeight;

  div {
    display: flex;
  }

  .section_header {
    width: 100%;
    padding: 0px $padHor;
    height: max-content;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .sub {
      color: $colSub;
      font-size: $font-sm;
      span {
        color: $other;
      }
    }
    .main {
      margin: 10px 0px;
      font-size: $font-lg;
    }
  }
}
