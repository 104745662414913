footer {
  width: 100%;
  padding: $padHor;
  background: #111;
  color: $colWh;
  font-weight: 400;
  div {
    display: flex;
  }
  .foot {
    width: 100%;
    line-height: 1.5;
    flex-direction: row;
    justify-content: space-between;
    .block_1 {
      width: 250px;
      flex-direction: column;
      .footer_logo {
        font-weight: 500;
        margin-bottom: 20px;
        font-size: $font-md;
      }
      .footer_description {
        // font-size: $font-rg;
        line-height: 2;
      }
    }

    .other_block {
      gap: 40px;
      color: #aaa;
      flex-direction: row;
      .contact_block {
        gap: 20px;
        width: 300px;
        flex-direction: column;
        .header {
          color: $colWh;
          font-weight: 500;
          font-size: $font-rg;
        }
        ul {
          gap: 20px;
          display: flex;
          margin-top: 5px;
          flex-direction: column;
          li {
            gap: 10px;
            display: flex;
            flex-direction: row;
            i {
              margin-top: 4px;
            }
          }
        }
      }
      .newsletter {
        gap: 20px;
        width: 300px;
        flex-direction: column;
        .header {
          color: $colWh;
          font-weight: 500;
          font-size: $font-rg;
        }
        .sub_txt {
        }
        .news_input {
          border-bottom: 2px solid $colSub;
          input {
            flex: 1;
            padding: 10px 0px;
            color: $colWh;
          }
          button {
            color: $colWh;
            display: flex;
            cursor: pointer;
            font-size: $font-rg;
            padding-left: 15px;
            align-items: flex-end;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }
}
