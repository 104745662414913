.strategy_sect {
  width: 100%;
  padding: $navHeight $padHor 100px;
  flex-direction: column;
  .section_topbar {
    align-items: flex-end;
    flex-direction: row;
    .item {
      color: $colWh;
      padding: 25px 30px;
      background: #000;
    }
  }
  .section_content {
    width: 100%;
    overflow: hidden;
    height: max-content;
    flex-direction: row;
    .section_text {
      width: 40%;
      color: $colSub;
      line-height: 1.8;
      padding-top: 30px;
      flex-direction: column;
      .first_text {
        margin-bottom: 10px;
      }
      .inner {
        gap: 10px;
        margin-top: 20px;
        flex-direction: row;
        .illust {
          flex: none;
          width: 100px;
          display: none;
        }
        .texts {
          flex-direction: column;
          .sub_header {
            color: $colMain;
          }
        }
      }
    }
    .section_image {
      flex: 1;
      margin-left: 150px;
      overflow: hidden;
      img {
        flex: none;
        width: 100%;
        aspect-ratio: 1;
        max-height: 370px;
        object-fit: cover;
      }
    }
  }
}
