.lets_build {
  gap: 20px;
  width: 100%;
  padding-top: 10vh;
  min-height: 90vh;
  padding: 120px $padHor 0px;
  flex-direction: row;
  position: relative;

  .section_images {
    width: 500px;
    margin-right: calc(270px * 0.3);
    max-height: 500px;
    // height: min-content;
    position: relative;
    .img_container {
      width: 500px;
      flex: none;
      max-height: 500px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      &.small {
        right: 0px;
        width: 250px;
        height: 275px;
        bottom: 0px;
        transform: translate(30%, 20%);
        position: absolute;
        img {
          border: 2px solid $colSub;
        }
      }
    }
  }

  &_text {
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    &_inner {
      width: 80%;
      flex-direction: column;
      .header_txt {
        color: $colSub;
        text-transform: uppercase;
      }
      .big_text {
        margin-top: 20px;
        line-height: 1.2;
        font-size: $font-lg;
        text-transform: capitalize;
      }
      .sub_text {
        color: $colSub;
        font-size: $font-rg;
        margin-top: 30px;
      }
      .brief {
        font-weight: 400;
        line-height: 2;
        margin-top: 30px;
      }
      .services_list {
        gap: 20px;
        display: flex;
        margin-top: 30px;
        flex-direction: column;
        li {
          gap: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          i {
            color: $other;
          }
        }
      }
    }
  }
}
