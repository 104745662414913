.hero_ {
  gap: 20px;
  width: 100%;
  min-height: 80vh;
  flex-direction: row;
  padding: 0px $padHor;
  &text {
    width: 100%;
    max-width: 520px;
    padding-top: 24vh;
    flex-direction: column;
    .main {
      line-height: 1.3;
      font-size: $font-lr;
    }
    .sub {
      color: #444;
      font-size: $font-tk;
      margin-top: 20px;
      padding-right: 20px;
      line-height: 1.4;
    }
    .get_products {
      color: $colWh;
      cursor: pointer;
      margin-top: 20px;
      border-radius: 10px;
      font-size: $font-sm;
      padding: 10px 20px;
      background: $other;
    }
  }
  &design {
    flex: 1;
    width: 100%;
    padding-top: 21vh;
    align-items: center;
    align-items: flex-end;
    flex-direction: column;
    // justify-content: center;
    .hero_img {
      width: 600px;
      aspect-ratio: 1.6;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        max-height: 50vh;
        object-fit: cover;
        border-radius: 100%;
        border-radius: 10px;
        border: 1px solid #ccc;
      }
      .img_border {
        top: 30px;
        left: 30px;
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        background: #e1e1e1;
        border-radius: 20px;
        border: 2px solid #ccc;
        border-bottom-right-radius: 20px;
      }
    }
  }
  @include media("<=tablet") {
    flex-direction: column;
  }
}
