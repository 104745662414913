.industries_served {
  gap: 20px;
  width: 100%;
  padding: 0px $padHor;
  padding-top: 10vh;
  min-height: 100vh;
  position: relative;
  padding-bottom: 70px;
  flex-direction: column;
  .industries_imgs {
    gap: 40px;
    width: 100%;
    margin: 20px 0px 0px;
    overflow: hidden;
    flex-direction: row;
    .industry_item {
      flex: 1;
      overflow: hidden;
      flex-direction: column;
      .industry_img {
        aspect-ratio: 1;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .industry_details {
        padding: 40px 35px;
        background: #f3f3f3;
        flex-direction: column;
        .ind_title {
          margin-bottom: 20px;
          font-size: $font-md;
        }
        .ind_brief {
          color: $colSub;
          line-height: 1.8;
        }
      }
    }
  }
}
