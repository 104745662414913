.gallery_sect {
  min-height: 80vh;
  margin-top: 10vh;
  padding-bottom: 100px;
  flex-direction: column;
  .image_slider {
    width: 100%;
    height: 28vw;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    // justify-content: center;
    .slider_inner {
      height: 100%;
      min-width: 100%;
      position: absolute;
      flex-direction: row;
      .img_cont {
        width: 35vw;
        flex: none;
        height: 100%;
        overflow: hidden;
        margin-left: 40px;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .img_text {
          opacity: 0;
          width: 100%;
          height: 100%;
          padding: 30px;
          color: #ddd;
          line-height: 1.6;
          position: absolute;
          flex-direction: column;
          transition: opacity 0.4s;
          justify-content: flex-end;
          background: rgba($color: #000, $alpha: 0.5);
          .main {
            color: #fff;
            margin-bottom: 10px;
          }
        }
        &:hover {
          cursor: pointer;
          .img_text {
            opacity: 1;
          }
        }
      }

      animation: scroll 50s linear infinite;
      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }
        // 22% {
        //   transform: translateX(0%);
        // }
        // 25% {
        //   transform: translateX(-12.5%);
        // }
        // 47% {
        //   transform: translateX(-12.5%);
        // }
        // 50% {
        //   transform: translateX(-25%);
        // }
        // 72% {
        //   transform: translateX(-25%);
        // }
        // 75% {
        //   transform: translateX(-37.5%);
        // }
        // 97% {
        //   transform: translateX(-37.5%);
        // }
        100% {
          transform: translateX(-50%);
        }
      }
    }
  }
}
