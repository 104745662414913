nav {
  top: 0px;
  width: 100%;
  z-index: 999;
  display: flex;
  position: fixed;
  background: $bg;
  height: $navHeight;
  flex-direction: row;
  align-items: center;
  padding: 0px $padHor;
  border-bottom: $border;
  justify-content: space-between;

  div {
    display: flex;
  }

  .app_logo {
    font-size: $font-rg;
  }

  .links_period {
    gap: 20px;
    flex-direction: row;
    .nav_links {
      gap: 10px;
      display: flex;
      cursor: pointer;
      // color: $colSub;
      align-items: center;
      flex-direction: row;
      li {
        padding: 0px 10px;
      }
    }
    .contact {
      color: $colWh;
      cursor: pointer;
      padding: 7px 14px;
      font-size: $font-sl;
      border-radius: 20px;
      background: $other;
    }
  }
}
