// $bg: #e1dfdd;
$bg: #eaeaea;
$colWh: #fff;
$colSub: #666;
$colMain: #000;
$silver: #b2b2b2;
$other: #2b0fff;

// Dimensions
$padHor: 6vw;
$font-sl: 14px;
$font-sm: 16px;
$font-tk: 18px;
$font-rg: 20px;
$font-md: 24px;
$font-bg: 30px;
$font-lg: 48px;
$font-lr: 56px;
$font-xl: 64px;
$navHeight: 100px;

$border: 1px solid #d1d1d1;

// Font
$sans: "General Sans", sans-serif;
